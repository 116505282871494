import React, { useContext, useEffect } from "react"
import { useRouter } from "next/router"
import { notification, Spin } from "antd"
import styled from "styled-components"
import { AuthContext } from "./utils/AuthContext"
import { userPool } from "@project/shared"
import { useTranslation } from "react-i18next"
import { SideBar } from "./components"

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
`

const PrivateRoute = (AuthComponent) => {
  function PrivateComponent({ children }) {
    const router = useRouter()
    const { t } = useTranslation()
    const { authenticated, loading, user, isAdmin, setUser } =
      useContext(AuthContext)
    const loggedInUser = userPool.getCurrentUser()

    useEffect(() => {
      const { pathname } = router

      if (!loading) {
        if (!authenticated) {
          router.push("/login")
          return
        }
        if (pathname.startsWith("/admin-management") && isAdmin) {
          loggedInUser.signOut()
          setUser(null)
          router.push("/login")
          notification.error({
            message: t("Not authorized."),
            placement: "top",
          })
        }
        if (authenticated && pathname === "/") {
          router.push("/home")
        }
      }
    }, [authenticated, loading, user, isAdmin])

    if (loading) {
      return (
        <LoaderWrapper>
          <Spin size={"large"} className={"loader"} />
        </LoaderWrapper>
      )
    }
    return (
      <>
        {authenticated && (
          <>
            {" "}
            <SideBar>{children}</SideBar>{" "}
          </>
        )}{" "}
      </>
    )
  }

  return class Higher extends React.Component {
    render() {
      return (
        <PrivateComponent>
          <AuthComponent {...this.props} />
        </PrivateComponent>
      )
    }
  }
}

export default PrivateRoute
